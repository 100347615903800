<template>
  <div>
    <titleBar
      :title="'Integration'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <h2 class="title is-4">Message settings</h2>

        <headerManage
          title="Name & description"
          :sub="blueprint.name ? blueprint.name : 'Not set'"
          desc="How this tools appears in lists everywhere."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field label="Name:">
              <b-input v-model="blueprint.name"></b-input>
            </b-field>

            <b-field label="Description:">
              <b-input
                v-model="blueprint.desc"
                type="textarea"
                placeholder="This text will help people find your image generator template"
              ></b-input>
            </b-field>

            <b-field label="Story image URL:">
              <b-input v-model="blueprint.image"></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Messaging prompt"
          :sub="blueprint.systemMsg ? blueprint.systemMsg : 'Not set'"
          desc="Optional. Tailor the messaging prompt used by your bot. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNo label="">
              <b-input
                type="textarea"
                placeholder="Imiadately as a stone age internet user. Short responses. Conversational styles. Grunts and emojis."
                v-model="blueprint.systemMsg"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Intro message"
          :sub="blueprint.defaultOpenerMsg ? blueprint.defaultOpenerMsg : 'Not set'"
          desc="Optional. Static message users receive when oppening the chat."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="Opener">
              <b-input
                type="textarea"
                placeholder="Yo! What do you want to joke about? "
                v-model="blueprint.defaultOpenerMsg"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="Engine"
          :sub="blueprint.messagingEngine == 'gpt4' ? 'Smart' : 'Fast (Default)'"
          desc="Pick the engine that works best for your bot. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="Engine">
              <b-select v-model="blueprint.messagingEngine" placeholder="Select engine" :disabled="posting">
                <option value="gpt3">Default (speed-optimized GPT3.5)</option>
                <option value="gpt4">Smart (slower & pricier GPT4)</option>
                <!--  
              <option value="" disabled>Other (soon)</option>-->
              </b-select>
            </b-field>

            <b-field horizontal label="Creativity">
              <b-slider v-model="blueprint.creativity" :min="1" style="max-width: 200px"></b-slider>
            </b-field>
          </div>
        </headerManage>

        <hr />
        <hr />
        <hr />
        <headerManage
          title="Website embed"
          sub="Not installed "
          desc="Embed your bot on your website"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>Copy this embed code on your site. Contact us to enable this feature or for integration help.</p>
            <!-- You can even use other components here -->
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title=" Twitter bot"
          :sub="bot.twitterAppUserToken ? 'Set' : 'Not set'"
          desc="Post automatically to Twitter using your APP_USER_TOKEN"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="App user token">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.twitterAppUserToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title="Discord bot"
          sub="Not set "
          desc="Let people chat with your bot on Discord"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>
              Add one of your bot to Discord. Works great for one-shot bots. It's free and takes 5 minutes to get a
              Discord API key.
            </p>
            <!-- You can even use other components here -->

            <b-field horizontal label=" ">
              <a
                href="https://discord.com/developers/applications"
                target="_blank"
                class="button is-small is-dark is-outlined"
              >
                <span>Get my Discord keys </span>
              </a>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-4">
                <span>Discord</span>
              </h2>
            </b-field>

            <b-field horizontal label="Application ID">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.discordAppId"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label="Discord Bot Token">
              <b-input
                size="is-"
                placeholder="MTA4NTU0NTYwMDUyODk1NzU.xxxxx"
                v-model="bot.discordBotToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-5">
                <span>Showcasing this bot on your own server? </span>
              </h2>
            </b-field>

            <b-field horizontal label="Custom invite URL to your bot's channel">
              <b-input
                size="is-"
                placeholder="123456789"
                v-model="bot.discordBotCustomInviteUrl"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>

            <b-field horizontal label="Forum Thread URL">
              <div>
                <b-input
                  size="is-"
                  placeholder="https://discord.com/channels/1083423353014075513/1085890710034718871"
                  v-model="bot.discordDedicatedForumthreadId"
                  :loading="posting"
                  :disabled="posting"
                  type="url"
                ></b-input>
                Your bot will respond to OP messages, even if not @mentionned.
              </div>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="ActivityPub  "
          sub="Coming soon!"
          desc="User of the fediverse will be able to subscribe & interact with your bot."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p></p>
          </div>
        </headerManage>

        <!-- API-->
        <headerManage
          title="API Access   "
          sub="Disabled. Access your bot programaticall from your own app or services."
          desc="Contact us to enable this feature or for integration help."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="API access">
              <div>
                <b-radio v-model="bot.apiAccess" native-value="off" type="is-info" :disabled="posting">
                  No API access
                </b-radio>
                <br />
                <b-radio v-model="bot.apiAccess" native-value="secprivateret" type="is-info" :disabled="posting">
                  Private (only your apps) <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="authenticated" type="is-info" :disabled="posting">
                  Authenticated apps <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="embed" type="is-info" :disabled="posting">
                  Selected websites can embed it <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="public" type="is-info" :disabled="posting">
                  Full public access <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <!-- 
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
              </div>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">AI models API keys</h2>
        <headerManage
          title="  OpenAI API key"
          :sub="bot.openAiKey ? 'Set' : 'Not set (optional)'"
          desc="Use a custom openAI key for this bot"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="API key">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.openAiKey"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <!-- 
        <b-field horizontal label="discordBotChannelId">
          <b-input
            size="is-"
            placeholder="123456789"
            v-model="bot.discordBotChannelId"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field> -->

        <!-- END     
    -->
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    blueprint: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      console.log("save from opt message;24");
      this.$emit("save");
    },
  },
};
</script>

<style></style>
