<template>
  <div>
    <titleBar
      :title="'Templates'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <!-- Modal for New Form using Buefy's Modal Component -->

        <BotBlueprintImageForm v-model="blueprint" @submit="handleFormSubmit" v-if="blueprint" />

        <hr />
        <hr />
        <hr />
        <h2 class="title is-4">Templates</h2>

        <div>
          <roundTagRow
            :item="blueprints"
            :selectedIndex="selectedTemplateIndex"
            @select="(i) => (selectedTemplateIndex = i)"
            :expandOnLoad="true"
          />
          <!-- Show button for creation -->
          <b-button v-if="!selectedTemplate" outlined rounded type="is-dark is-small" @click="openNewTemplateModal"
            >Add New Template</b-button
          >

          <div class="columns">
            <div class="column">
              <!-- Edit Form -->
              <div v-if="selectedTemplate && !isNewTemplateMode">
                <templatePill
                  :item="selectedTemplate"
                  :closable="true"
                  @clear="selectedTemplateIndex = null"
                  style="margin-bottom: 10px"
                />

                <BotBlueprintImageForm v-model="selectedTemplate" @submit="handleFormSubmit" v-if="selectedTemplate" />
              </div>
            </div>
            <div class="column">
              <h2 class="title is-4">output</h2>
            </div>
          </div>
        </div>

        <!-- 


    <h2 class="title is-4">Bot basics</h2>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import BotBlueprintImageForm from "@/components/studio/BotBlueprintImageForm.vue";
import templatePill from "@/components/e/templatePill.vue";
import roundTagRow from "@/components/nav/roundTagRow.vue";

export default {
  components: {
    BotBlueprintImageForm,
    roundTagRow,
    templatePill,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    blueprint: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blueprints: [],
      selectedTemplate: null,
      selectedTemplateIndex: null,
      isNewTemplateMode: false,
      newTemplate: {},
    };
  },
  computed: {
    botId() {
      return this.bot.handle;
    },
  },
  methods: {
    openNewTemplateModal() {
      this.isNewTemplateMode = true;
      this.newTemplate = {};
    },

    handleFormSubmit(template) {
      console.log("RECEIVED from comp: ", template);
      if (template.id) {
        // Handle update logic
        window.API.editBotBlueprint(this.botId, template.id, template).then((res) => {
          if (res) {
            this.blueprints[this.selectedTemplateIndex] = res.template; // Update the list at specific index
            console.log("Template updated successfully!");
          } else {
            console.error("Failed to update the template");
          }
          this.selectedTemplate = null;
          this.selectedTemplateIndex = null;
          //buefy toast show updated
          this.$buefy.toast.open({
            message: "Template updated successfully!",
            type: "is-success",
            position: "is-bottom",
            duration: 5000,
            queue: false,
          });
        });
      }
    },

    handleFormSubmitForNew(template) {
      // Handle creation logic for new template
      template.type = "image";
      window.API.createBotBlueprint(this.botId, { blueprint: template }).then((res) => {
        if (res) {
          this.blueprints.push(res);
          console.log("Template created successfully!");
        } else {
          console.error("Failed to create the template");
        }
        this.isNewTemplateMode = false;
      });
    },

    loadTemplates() {
      window.API.getBotBlueprints(this.botId).then((res) => {
        this.blueprints = res;
      });
    },
  },
  watch: {
    selectedTemplateIndex(newValue) {
      if (newValue !== null && this.blueprints[newValue]) {
        this.selectedTemplate = this.blueprints[newValue];
      } else {
        this.selectedTemplate = null;
      }
    },
  },
  mounted() {
    this.loadTemplates();
  },
};
</script>
