<template>
  <div>
    <BlueprintOptionsMessage
      v-if="blueprint?.type == 'chat'"
      :bot="bot"
      :posting="posting"
      :blueprint="blueprint"
      @save="save"
    />
    <BlueprintOptionsImage
      v-if="blueprint?.type == 'image'"
      :bot="bot"
      :posting="posting"
      :blueprint="blueprint"
      @save="save"
    />

    <hr />
    <h2 class="title is-4">Danger zone</h2>

    <b-button outlined type="is-danger is-small" @click="deleteButton">Delete this function</b-button>

    <div class="sectionNA" v-show="false">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <h2 class="title is-4">Your bot, elsewhere</h2>
        <headerManage
          title="Website embed"
          sub="Not installed "
          desc="Embed your bot on your website"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>Copy this embed code on your site. Contact us to enable this feature or for integration help.</p>
            <!-- You can even use other components here -->
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title=" Twitter bot"
          :sub="bot.twitterAppUserToken ? 'Set' : 'Not set'"
          desc="Post automatically to Twitter using your APP_USER_TOKEN"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="App user token">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.twitterAppUserToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <headerManage
          title="Discord bot"
          sub="Not set "
          desc="Let people chat with your bot on Discord"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p>
              Add one of your bot to Discord. Works great for one-shot bots. It's free and takes 5 minutes to get a
              Discord API key.
            </p>
            <!-- You can even use other components here -->

            <b-field horizontal label=" ">
              <a
                href="https://discord.com/developers/applications"
                target="_blank"
                class="button is-small is-dark is-outlined"
              >
                <span>Get my Discord keys </span>
              </a>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-4">
                <span>Discord</span>
              </h2>
            </b-field>

            <b-field horizontal label="Application ID">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.discordAppId"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label="Discord Bot Token">
              <b-input
                size="is-"
                placeholder="MTA4NTU0NTYwMDUyODk1NzU.xxxxx"
                v-model="bot.discordBotToken"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>

            <b-field horizontal label=" ">
              <h2 class="title is-5">
                <span>Showcasing this bot on your own server? </span>
              </h2>
            </b-field>

            <b-field horizontal label="Custom invite URL to your bot's channel">
              <b-input
                size="is-"
                placeholder="123456789"
                v-model="bot.discordBotCustomInviteUrl"
                :loading="posting"
                :disabled="posting"
                type="url"
              ></b-input>
            </b-field>

            <b-field horizontal label="Forum Thread URL">
              <div>
                <b-input
                  size="is-"
                  placeholder="https://discord.com/channels/1083423353014075513/1085890710034718871"
                  v-model="bot.discordDedicatedForumthreadId"
                  :loading="posting"
                  :disabled="posting"
                  type="url"
                ></b-input>
                Your bot will respond to OP messages, even if not @mentionned.
              </div>
            </b-field>
          </div>
        </headerManage>

        <headerManage
          title="ActivityPub  "
          sub="Coming soon!"
          desc="User of the fediverse will be able to subscribe & interact with your bot."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p></p>
          </div>
        </headerManage>

        <!-- API-->
        <headerManage
          title="API Access   "
          sub="Disabled. Access your bot programaticall from your own app or services."
          desc="Contact us to enable this feature or for integration help."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="API access">
              <div>
                <b-radio v-model="bot.apiAccess" native-value="off" type="is-info" :disabled="posting">
                  No API access
                </b-radio>
                <br />
                <b-radio v-model="bot.apiAccess" native-value="secprivateret" type="is-info" :disabled="posting">
                  Private (only your apps) <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="authenticated" type="is-info" :disabled="posting">
                  Authenticated apps <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="embed" type="is-info" :disabled="posting">
                  Selected websites can embed it <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <br />
                <b-radio v-model="bot.apiAccess" native-value="public" type="is-info" :disabled="posting">
                  Full public access <span class="tag is-warning is-light">Premium</span>
                </b-radio>

                <!-- 
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
              </div>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">AI models API keys</h2>
        <headerManage
          title="  OpenAI API key"
          :sub="bot.openAiKey ? 'Set' : 'Not set (optional)'"
          desc="Use a custom openAI key for this bot"
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontal label="API key">
              <b-input
                size="is-"
                placeholder="1085545600520"
                v-model="bot.openAiKey"
                :loading="posting"
                :disabled="posting"
                type="text"
              ></b-input>
            </b-field>
            <OtherComponent />
          </div>
        </headerManage>

        <!-- 
        <b-field horizontal label="discordBotChannelId">
          <b-input
            size="is-"
            placeholder="123456789"
            v-model="bot.discordBotChannelId"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field> -->

        <!-- END     
    -->
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
import BlueprintOptionsMessage from "@/views/BlueprintOptionsMessage.vue";
import BlueprintOptionsImage from "@/views/BlueprintOptionsImage.vue";
export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
    BlueprintOptionsMessage,
    BlueprintOptionsImage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    blueprint: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    deleteButton() {
      //confirm with buefy dialog than emit delete
      this.$buefy.dialog.confirm({
        title: "Deleting this function",
        message: "Are you sure? This cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.blueprint.deleted = true;
          this.$emit("delete");

          this.$emit("save");
        },
      });
      this.$emit("delete");
    },
  },
};
</script>

<style></style>
